<template>
  <div>
    <vs-row>
      <vs-col vs-w="12" vs-sm="12" vs-xs="12" v-if="!transferringVpcNumber">
        <vpclist
          @transferringVpcNumber="transferringVpcNumber = $event"
        ></vpclist>
      </vs-col>
    </vs-row>
    <vs-row class="flex justify-between">
      <vs-col
        vs-w="12"
        vs-sm="12"
        vs-xs="12"
        v-if="!transferringTargetNodeData"
      >
        <dataCenters
          @TargetNodeSelected="transferringTargetNodeData = $event"
          :DCList="dataCenterList"
        ></dataCenters>
      </vs-col>
    </vs-row>
    <vs-row class="flex">
      <vs-col
        class="my-5"
        vs-w="6"
        vs-sm="12"
        vs-xs="12"
        v-if="transferringVpcNumber"
      >
        <vx-card class="h-full">
          <vs-button
            radius
            color="danger"
            size="small"
            icon="close"
            @click="transferringVpcNumber = ''"
          ></vs-button>
          <div>
            <vue-json-pretty :data="transferringVpcNumber"> </vue-json-pretty>
          </div>
        </vx-card>
      </vs-col>
      <vs-col
        class="my-5"
        vs-w="6"
        vs-sm="12"
        vs-xs="12"
        v-if="transferringTargetNodeData"
      >
        <vx-card class="h-full">
          <vs-button
            radius
            color="danger"
            size="small"
            icon="close"
            @click="transferringTargetNodeData = ''"
          ></vs-button>
          <vue-json-pretty :data="transferringTargetNodeData">
          </vue-json-pretty>
        </vx-card>
      </vs-col>

      <vx-card
        v-if="transferringTargetNodeData && transferringVpcNumber"
        class="w-full"
      >
        <div class="flex justify-center">
          از انتقال جزیره
          <p class="text-warning mx-3">
            {{ transferringVpcNumber.vpc_number }} -
            {{ transferringVpcNumber.vpc_name }}
          </p>
          به نود
          <p class="text-warning mx-3">
            {{ transferringTargetNodeData.node.title }}
          </p>
          اطمینان دارید
        </div>
        <div class="flex justify-center">
          <vs-collapse>
            <vs-collapse-item not-arrow>
              <vs-button
                color="success"
                type="gradient"
                class="w-full"
                slot="header"
                >ادامه</vs-button
              >
              <p class="w-full"></p>
              <vs-button
                @click="
                  Transfer({
                    vpc_number: transferringVpcNumber.vpc_number,
                    node_id: transferringTargetNodeData.node.id,
                  })
                "
                type="gradient"
                class="w-full my-5"
                >انتقال</vs-button
              >
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>
    </vs-row>
  </div>
</template>
<script>
import dataCenters from "./components/datacenters.vue";
import vpclist from "./components/vpclist.vue";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
export default {
  components: {
    dataCenters,
    vpclist,
    VueJsonPretty,
  },
  data() {
    return {
      dataCenterList: [],
      //================= transferring data=================
      transferringVpcNumber: "",
      transferringTargetNodeData: "",
      //================= transferring data=================
    };
  },
  methods: {
    logger(t) {
      console.log(t);
    },
    Transfer(data) {
      this.$http
        .post("/admin/vira/cloud/vpc/migrate", {
          vpc_number: data.vpc_number,
          target_node_id: data.node_id,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>